import { FormControl, FormHelperText, Grid, Link, makeStyles, Select, TextField } from '@material-ui/core';
import React from 'react';
import InputMask from 'react-input-mask';
import { useHistory } from "react-router-dom";
import changeMask from '../../component/common/function-mask';
import Loading from '../common/Loading';
import TermsDialog from '../common/TermsDialog';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    input: {
        width: "100%"
    },
    buttonGrid: {
        marginTop: 2,
        marginBottom: 3
    }
}));

const ContactSearch = (props) => {
    const classes = useStyles();
    const history = useHistory();

    const [openTerm, setOpenTerm] = React.useState(false);
    const [contact, setContact] = React.useState({
        name: "",
        type: "",
        description: ""
    });
    const [required, setRequired] = React.useState({
        name: false,
        type: false,
        description: false
    });
    const [mask, setMask] = React.useState({ mask: "", description: "Descrição do Contato"});
    const [loading, setLoading] = React.useState(false);

    const changeValue = (event) => {
        setContact({ ...contact, [event.target.name]: event.target.value });
        setRequired({ ...required, [event.target.name]: false });
    }

    const changeSelectType = (event) => {
        setContact({ ...contact, description: "" });
        const value = event.target.value;
        setMask(changeMask(value));
    }

    const validFields = () => {
        if (contact.name === "") {
            setRequired({ ...required, name: true });
            return false;
        }

        if (contact.type === "") {
            setRequired({ ...required, type: true });
            return false;
        }

        if (contact.description === "") {
            setRequired({ ...required, description: true });
            return false;
        }

        return true;
    }

    const goToLogin = () => {
        history.push('/login');
    }

    const sendContact = () => {
        if (validFields()) {
            setLoading(true);
            var search = props.search;
            search.contactSearch = {
                name: contact.name,
                contactList: [
                    {
                        type: contact.type,
                        description: contact.description
                    }
                ]
            };

            props.sendSearch(search);
        }
    }

    return (
        <div>
            <Loading open={loading} />
            <FormControl variant="outlined" className={classes.root} >
                <span className="title">Capturamos sua busca! Como gostaria de ser contactado por nossos forncedores se já possui casdastro, poderá fazer seu login &nbsp;
                    <Link href="#" onClick={goToLogin} className="link link-underline">clicando aqui</Link>
                </span>
                <br />
                <Grid container spacing={1} direction="row" justify="center">
                    <Grid item xs={12} md={12} sm={12}>
                        <TextField
                            error={required.name}
                            className={classes.input}
                            name="name"
                            id="name"
                            value={contact.name}
                            onChange={changeValue}
                            label="Como você gostaria de ser chamado?"
                            variant="outlined" />
                    </Grid>
                    <Grid item xs={4} md={4} sm={4}>
                        <Select
                            error={required.type}
                            native
                            name="type"
                            className={classes.input}
                            variant="outlined"
                            onChange={e => { changeSelectType(e); changeValue(e); }}
                            value={contact.type}
                        >
                            <option selected disabled value="">Tipo</option>
                            <option value="WHATSAPP">WhatsApp</option>
                            <option value="MAIL">Email</option>
                        </Select>
                    </Grid>
                    <Grid item xs={8} md={8} sm={8}>

                        {contact.type !== "MAIL" ?
                            <InputMask
                                mask={mask.mask}
                                value={contact.description}
                                disabled={false}
                                onChange={changeValue}
                                maskChar=" "
                            >
                                {() =>
                                    <TextField
                                        error={required.description}
                                        className={classes.input}
                                        name="description"
                                        id="outlined-basic"
                                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                        value={contact.description}
                                        label={mask.description}
                                        variant="outlined" />}
                            </InputMask> :

                            <TextField
                                error={required.description}
                                className={classes.input}
                                onChange={changeValue}
                                name="description"
                                id="outlined-basic"
                                type="email"
                                value={contact.description}
                                label="Informe seu email"
                                variant="outlined" />}

                    </Grid>
                </Grid>
                <Grid className={classes.buttonGrid} container spacing={2}>
                    <Grid item xs={12} md={12} sm={12}>
                        <button
                            className="input-button"
                            type="submit"
                            value="submit"
                            name="submit"
                            onClick={sendContact}>Enviar Informações de Contato</button>
                    </Grid>
                </Grid>
            </FormControl>
            <FormHelperText id="my-helper-text">Ao enviar a busca você concorda com nossos
                <Link className="link link-underline" href="#" onClick={() => setOpenTerm(true)}> termos e condições</Link>.
            </FormHelperText>
            <TermsDialog closeTerms={() => setOpenTerm(false)} openTerm={openTerm} />
        </div >
    );
}

export default ContactSearch;