import superagent from "superagent";

const platformHost = "https://platform.deixaqueeuprocuro.com.br";
// const platformHost = "http://localhost:8080";

const requestPost = (path, body) => {
    return superagent
        .post(`${platformHost}${path}`)
        .send(body)
        .set("Content-Type", "application/json")
        .set("authorization", `${localStorage.getItem("jwt")}`)
        .then((res) => res)
        .catch((err) => {
            return err;
        });
};

const requestPut = (path, body) => {
    return superagent
        .put(`${platformHost}${path}`)
        .send(body)
        .set("Content-Type", "application/json")
        .set("authorization", `${localStorage.getItem("jwt")}`)
        .then((res) => res)
        .catch((err) => {
            return err;
        });
};


const requestGet = (path) => {
    return superagent
        .get(`${platformHost}${path}`)
        .set("Content-Type", "application/json")
        .set("authorization", `${localStorage.getItem("jwt")}`)
        .then((res) => res)
        .catch((err) => err.status);
};

const PlatformRequest = {
    getBrandList: () => requestGet(`/v1/searchs/brands`),
    getSegmentList: () => requestGet(`/v1/searchs/segments`),
    postLogin: (body) => requestPost(`/v1/auth/login`, body),
    postSearch: (body) => requestPost(`/v1/searchs`, body),
    postClient: (body) => requestPost('/v1/clients', body),
    getExistClient: (id) => requestGet(`/v1/clients/${id}/exist`),
    getClientFromToken: () => requestGet(`/v1/clients/token`),
    updateClientFromToken: (body) => requestPut(`/v1/clients/token`, body),
    resetPasswordRequest: (body) => requestPost(`/v1/auth/reset`, body),
    validateTokenReset: (token) => requestGet(`/v1/auth/reset/${token}/validate`),
    registerNewPassword: (body) => requestPut(`/v1/auth/reset`, body)
};

export default PlatformRequest;
