// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

// Common Layout
// import Layout from "./component/common/App";


// Home layout
import Home from './home/Home';

// Element Layout
import About from "./elements/About";
import DataPrivacy from "./elements/DataPrivacy";
import Contact from "./elements/Contact";
import error404 from "./elements/error404";
import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import Login from './elements/Login';
import SearchSuccess from './elements/SearchSuccess';
import Register from './elements/Register';
import Client from './elements/Client';
import BeProvider from './elements/BeProvider';
import ResetPassword from './elements/ResetPassword';


class Root extends Component{
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <Switch>
                    <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/about`} component={About}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/login`} component={Login}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/provider`} component={BeProvider}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/success`} component={SearchSuccess}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/register`} component={Register}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/client`} component={Client}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/reset`} component={ResetPassword}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/privacidade`} component={DataPrivacy}/>
                    <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
                    <Route component={error404}/>
                </Switch>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();