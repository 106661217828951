import React from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Footer from "../component/footer/Footer";
import Header from "../component/header/Header";
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    text: {
        textAlign: "justify",
        fontSize: "1em",
        lineHeight: 1.5
    }
}));

const DataPrivacy = () => {
    const classes = useStyles();
    return (
        <React.Fragment>
            <PageHelmet pageTitle='Políticas de Privacidade' />

            <Header colorblack="color--black" logoname="logo.png" />

            {/* Start Breadcrump Area */}
            <div className="rn-page-title-area pt--200 pb--110 bg_image bg_image--17" data-black-overlay="6"></div>
            {/* End Breadcrump Area */}

            {/* Start Contact Page Area  */}
            <div className="rn-about-area ptb--50 bg_color--1">
                <div className="rn-about-wrapper">
                    <div className="container">
                        <div className="row mt--30">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                Ao usuário do <b><b>Deixa Que Eu Procuro</b></b>, <br /><br />
                                <p className={classes.text}> Obrigado por dedicar este tempo para ler o Sumário do contrato do <b><b>Deixa Que Eu Procuro</b></b>.</p>
                                <p className={classes.text}>Estas informações o ajudarão a operar na nossa plataforma de anúncios. É muito importante que conheça as nossas políticas e, por esta razão, é necessário que leia e compreenda os Termos e condições gerais de uso do site, bem como a leitura das cláusulas que viabilizam a contratação e as limitativas de direitos, aqui destacadas: </p>
                                <p className={classes.text}>O <b>Deixa Que Eu Procuro</b> não é fornecedor de quaisquer produtos ou serviços anunciados no site. O <b>Deixa Que Eu Procuro</b> presta um serviço consistente na oferta de uma plataforma na internet que fornece espaços para que Usuários, potenciais fornecedores, cadastrem seus seguimentos de atendimentos automotivos, oferecendo à disponibilidade de estoque, dos seus produtos e serviços comercializados, para que eventuais interessados na compra dos itens, os Usuários, potenciais clientes, possam negociar direta e exclusivamente entre si; </p>
                                <p className={classes.text}>Os Usuários Fornecedores,(potenciais vendedores) somente poderão responder aos potenciais clientes, bens ou serviços que tenham em estoque e que estejam cadastrados no segmento procurado pelo potencial comprador, estabelecendo diretamente os termos da pesquisa e todas as suas características do bem ou serviço pesquisado. Informações como:  preço, categoria, quantidade, condições de venda, forma e prazo de entrega, além da forma de pagamento serão negociados entre si, fornecedor e cliente, não tendo o site, deixaqueeuprocuro.com.br, nenhuma responsabilidade quanto a esta negociação;</p>
                                <p className={classes.text}>Para utilizar os serviços do <b>Deixa Que Eu Procuro</b> o Usuário deve aceitar, expressamente, a Política de Privacidade e Confidencialidade da Informação. O Usuário, potencial cliente, deverá efetuar um cadastro único, criando um apelido e senha que são pessoais e intransferíveis. O <b>Deixa Que Eu Procuro</b> não se responsabiliza pelo uso inadequado e divulgação destes dados para terceiros. O <b>Deixa Que Eu Procuro</b>, nem quaisquer de seus empregados ou prepostos solicitará, por qualquer meio, físico ou eletrônico, que seja informada sua senha;</p>
                                <p className={classes.text}>O <b>Deixa Que Eu Procuro</b>, em razão de violação à legislação em vigor ou aos Termos e condições gerais de uso do <b>Deixa Que Eu Procuro</b>, conforme a situação, poderá, sem prejuízo de outras medidas, recusar qualquer solicitação de cadastro, advertir, suspender, temporária ou definitivamente, a conta de um Usuário, seus anúncios ou aplicar uma sanção que impacte negativamente sua reputação;</p>
                                <p className={classes.text}>Não é permitido anunciar produtos expressamente proibidos pela legislação vigente ou pelos Termos e condições gerais de uso do site, que não possuam a devida autorização específica de órgãos reguladores competentes, ou que violem direitos de terceiros; </p>
                                <p className={classes.text}>O <b>Deixa Que Eu Procuro</b> disponibiliza um sistema para que o usuário comprador opine sobre o usuário vendedor com relação à negociação realizada. A opinião será disponibilizada no perfil do usuário vendedor.</p>
                                <p className={classes.text}>O Usuário/potencial comprador, antes de decidir pela compra, deverá atentar-se às informações sobre a reputação do Usuário vendedor, ao preço, às formas de pagamento, à disponibilidade, à forma e ao prazo de entrega dos produtos e serviços, informações estas que serão discutidas e trocadas em contatos diretos, fornecedor e cliente, sem nenhuma intervenção do <b>Deixa Que Eu Procuro</b>. </p>
                                <p className={classes.text}>É vedado aos usuários anunciar à venda ou comprar produtos que sejam proibidos ou violem a legislação vigente, bem como sejam considerados produtos proibidos pelo site.</p>
                                <p className={classes.text}>O <b>Deixa Que Eu Procuro</b> firmou convênios e parcerias com diversas autoridades com o objetivo de promover e incentivar a solução amigável de controvérsias e evitar a judicialização de questões que podem ser resolvidas administrativamente, razão pela qual, os usuários aceitam e se comprometem a utilizar as ferramentas da plataforma (reclamação/ mediação) como primeiro meio para a solução de controvérsias decorrentes das compras e vendas realizadas no site <b>Deixa Que Eu Procuro</b>. Os usuários também poderão buscar a solução de controvérsias de consumo por meio do serviço www.consumidor.gov.br, mantido pela Secretaria Nacional do Consumidor (SENACON) do Ministério da Justiça e Segurança Pública, que é disponibilizado gratuitamente aos consumidores de todo o país.</p>

                                <h5>Termos e condições gerais de uso do site</h5>
                                <p className={classes.text}>Estes Termos e condições gerais aplicam-se ao uso dos serviços oferecidos pelo DEIXAQUEEUPROCURO.COM.BR LTDA, empresa devidamente inscrita no CNPJ/MF sob o n°, situada na Rua Coronel Antonio Benjamim Camargos, nº 321, Bloco 26, apartamento 402, Bairro Industrial Itaú, Contagem MG, doravante nominada <b>Deixa Que Eu Procuro</b>, por meio dos sites www.deixaqueeuprocuro.com.br (“Site”).</p>
                                <p className={classes.text}>Qualquer pessoa, doravante nominada Usuário, que pretenda utilizar os serviços do <b>Deixa Que Eu Procuro</b> deverá aceitar os Termos e condições gerais e todas as demais políticas e princípios que o regem.</p>
                                <p className={classes.text}>A aceitação destes Termos e condições gerais é absolutamente indispensável à utilização do Site e serviços prestados pelo <b>Deixa Que Eu Procuro</b>.</p>
                                <p className={classes.text}>O Usuário deverá ler, certificar-se de haver entendido e aceitar todas as condições estabelecidas nos Termos e condições gerais e nas Políticas de privacidade, assim como nos demais documentos a eles incorporados por referência, antes de seu cadastro como Usuário do <b>Deixa Que Eu Procuro</b>.</p>

                                <h4>Em caso de dúvidas, acesse Contato.</h4>
                                <b>01 - Objeto </b><br />
                                <p className={classes.text}>Os serviços objeto dos presentes Termos e condições gerais consistem em (i) ofertar e hospedar espaço no Sites para que os Usuários, potenciais compradores, pesquisem usuário, potenciais fornecedores de bens e/ou serviços e (ii) viabilizar o contato direto entre Usuários vendedores e Usuários interessados em adquirir os bens e ou serviços anunciados, por meio da divulgação dos dados de contato de uma parte à outra. O <b>Deixa Que Eu Procuro</b>, portanto, possibilita aos Usuários se contatarem e negociarem entre si diretamente, sem qualquer intervenção do <b>Deixa Que Eu Procuro</b>, na negociação ou na concretização dos negócios. Desta forma, ressalta-se que o <b>Deixa Que Eu Procuro</b> não fornece quaisquer produtos ou serviços.</p>
                                <p className={classes.text}>Para todos os fins, a compra e venda de produtos somente é formalizada após contato direto entre potenciais compradores e potenciais vendedores.</p>

                                <b>02 - Capacidade para cadastrar-se</b><br />
                                <p className={classes.text}>Os serviços do <b>Deixa Que Eu Procuro</b> estão disponíveis apenas para as pessoas físicas ou jurídicas que tenham capacidade legal para contratá-los.</p>
                                <p className={classes.text}>É proibido o cadastro de Usuários que não tenham capacidade civil (com relação a pessoas físicas) ou não sejam representantes legais (com relação a pessoas jurídicas), bem como de Usuários que tenham sido suspensos do <b>Deixa Que Eu Procuro</b>, temporária ou definitivamente, sem prejuízo da aplicação das sanções legais previstas no Código Civil Brasileiro, notadamente, art. 166, I; 171, I e 180.</p>
                                <p className={classes.text}>Inabilitação e suspensão de usuários</p>

                                <b>03 - Cadastro</b><br />
                                <p className={classes.text}>Apenas será confirmado o cadastramento do Usuário que preencher todos os campos obrigatórios do cadastro, com informações exatas, precisas e verdadeiras. O Usuário declara e assume o compromisso de atualizar os dados inseridos em seu cadastro (“Dados Pessoais”) sempre que for necessário.</p>
                                <p className={classes.text}>Ao se cadastrar no <b>Deixa Que Eu Procuro</b>, o Usuário poderá utilizar todos os serviços disponibilizados pelas empresas, no caso de usuários, potencias compradores, pesquisar fornecedores dos itens pesquisados e receber os contatos do potencias fornecedores nos quais responderem a pesquisa, afirmativamente, informando a disponibilidade de fornecimento do item da pesquisa, no caso usuários, potenciais fornecedores, receber por meio de aplicativo e disposição de informativos em seu cadastro que existem potenciais compradores para itens de seu portifólio, declarando, para tanto, ter lido, compreendido e aceitado os respectivos Termos e Condições de uso de cada um destes serviços que passam a fazer parte integrante destes Termos e condições gerais quando concluído o cadastro.</p>
                                <p className={classes.text}>O Usuário acessará sua conta através de e-mail ou apelido (login) e senha e compromete-se a não informar a terceiros esses dados, responsabilizando-se integralmente pelo uso que deles seja feito.</p>
                                <p className={classes.text}>O <b>Deixa Que Eu Procuro</b> não se responsabiliza pela correção dos Dados Pessoais inseridos por seus Usuários. Os Usuários garantem e respondem, em qualquer caso, civil e criminalmente pela veracidade, exatidão e autenticidade, dos Dados Pessoais cadastrados.</p>
                                <p className={classes.text}>O apelido que o Usuário utiliza no <b>Deixa Que Eu Procuro</b> não poderá guardar semelhança com o nome <b>Deixa Que Eu Procuro</b>, suas marcas e nomes de domínio ou outro bem de propriedade intelectual, tampouco poderá ser utilizado qualquer apelido que insinue ou sugira que os produtos/serviços anunciados pertencem ou tenham qualquer relação com o <b>Deixa Que Eu Procuro</b>. Também serão excluídos apelidos considerados ofensivos, bem como os que contenham Dados Pessoais do Usuário ou alguma URL ou endereço eletrônico.</p>
                                <p className={classes.text}>O Mercado Livre se reserva o direito de recusar qualquer solicitação de cadastro e de suspender um cadastro previamente aceito, que esteja em desacordo com as políticas e regras dos presentes Termos e condições gerais.</p>
                                <p className={classes.text}>Com relação ao serviço de classificados disponibilizado nos sites, o <b>Deixa Que Eu Procuro</b>, a seu critério, poderá requerer um cadastro adicional aos Usuários que operarem como concessionárias, como requisito para que estes tenham acesso aos pacotes de publicações especiais. Nestes casos, uma vez efetuado o cadastro adicional, os anúncios de automóveis feitos pelas concessionárias somente serão publicados no <b>Deixa Que Eu Procuro</b> por meio da contratação de pacotes ofertados pelo <b>Deixa Que Eu Procuro</b> ou sob outras modalidades que o <b>Deixa Que Eu Procuro</b> venha a permitir para estes tipos de Usuários.</p>
                                <p className={classes.text}>O Usuário, potencial fornecedor, reconhece que é o único responsável pela veracidade e pelo conteúdo disponibilizado no seu anúncio e respostas das pesquisas efetuadas pelos Usuários, potenciais compradores, isentando o <b>Deixa Que Eu Procuro</b> de qualquer responsabilidade nesse sentido, bem como declara, nos termos da lei, estarem quaisquer bens e serviços anunciados em plena regularidade.</p>
                                <p className={classes.text}>O <b>Deixa Que Eu Procuro</b> poderá, a seu exclusivo critério, realizar as buscas que julgar necessárias para apurar dados incorretos ou inverídicos, solicitar dados adicionais e documentos que estime serem pertinentes a fim de conferir os Dados Pessoais informados e, ainda, recorrer a base de dados públicas ou privadas, podendo o usuário editá-las caso entenda que tais dados não estejam atualizados.</p>
                                <p className={classes.text}>Caso o <b>Deixa Que Eu Procuro</b> decida checar a veracidade dos dados cadastrais de um Usuário e se constate haver entre eles dados incorretos ou inverídicos, poderá recorrer a dados publicamente disponíveis ou a bases de dados comerciais para alterar ou enriquecer os Dados Pessoais, ou, ainda caso o Usuários se furte ou se negue a enviar os documentos requeridos, o <b>Deixa Que Eu Procuro</b> poderá suspender temporariamente ou definitivamente a conta, sem prejuízo de outras medidas que entender necessárias e oportunas.</p>
                                <p className={classes.text}>Havendo a aplicação de quaisquer das sanções acima referidas, automaticamente serão cancelados os anúncios do respectivo Usuário, não lhe assistindo, por essa razão, qualquer indenização ou ressarcimento.</p>
                                <p className={classes.text}>O Usuário compromete-se a notificar o <b>Deixa Que Eu Procuro</b> imediatamente, e por meio seguro, a respeito de qualquer uso não autorizado de sua conta, bem como seu acesso não autorizado por terceiros. O Usuário será o único responsável pelas operações efetuadas em sua conta, uma vez que o acesso só será possível mediante a inclusão da senha, que deverá ser de conhecimento e propriedade exclusiva do Usuário.</p>
                                <p className={classes.text}>Em nenhuma hipótese será permitida a cessão, venda, aluguel ou outra forma de transferência da conta (incluindo-se opiniões e comentários). Também não se permitirá a manutenção de mais de um cadastro por uma mesma pessoa, ou ainda a criação de novos cadastros por pessoas cujos cadastros originais tenham sido suspensos temporária ou definitivamente por infrações às políticas do <b>Deixa Que Eu Procuro</b>.</p>

                                <b>04 - Modificações dos Termos e condições gerais</b> <br />
                                <p className={classes.text}>O <b>Deixa Que Eu Procuro</b> poderá alterar, a qualquer tempo, estes Termos e condições gerais, visando seu aprimoramento e melhoria dos serviços prestados. Os novos Termos e condições gerais entrarão em vigor 10 (dez) dias após sua publicação no Site. No prazo de 5 (cinco) dias contados a partir da publicação da nova versão, o Usuário deverá comunicar-se por e-mail caso não concorde com os termos alterados. Nesse caso, o vínculo contratual deixará de existir, desde que não haja contas ou dívidas em aberto. Não havendo manifestação no prazo estipulado, entender-se-á que o Usuário aceitou os novos Termos e condições gerais de uso e o contrato continuará vinculando as partes.</p>
                                <p className={classes.text}>As alterações não vigorarão em relação às negociações e anúncios já iniciados antes de sua publicação, permanecendo, nestes casos, vigente a redação anterior.</p>


                                <b>05 – Fornecedores de bens e ou serviços</b><br />

                                5.1 Anúncios/ofertas<br />
                                <p className={classes.text}>O Usuário, potencial fornecedor, poderá cadastrar-se vinculando seu cadastro aos seguimentos automotivos nos quais oferece, informando suas respectivas categorias e subcategorias. O cadastro do Usuário, potencial fornecedor, não pode conter gráficos, textos, descrições, fotos, vídeos e outras informações referentes aos bens e ou serviço oferecido, sempre que tal prática violar um dispositivo previsto em lei, neste contrato, nas demais políticas do <b>Deixa Que Eu Procuro</b>. Presumir-se-á que, mediante a inclusão do cadastro no <b>Deixa Que Eu Procuro</b>, o Usuário manifesta a intenção e declara possuir o direito de vender o produto ou oferecer o serviço, além de dispor do produto para entrega imediata ou para a entrega no prazo especificado no anúncio. O Usuário declara que estará disponível para prestar o serviço no prazo acordado. Uma vez cadastrado, o usuário declara-se ciente e de acordo que as suas informações de contatos, disponibilizados no seu cadastro, serão repassadas aos usuários, potenciais clientes, que entrarão em contato direto com o Usuário, potencial fornecedor.</p>
                                <p className={classes.text}>O usuário declara ter conhecimento de que, ao realizar uma pesquisa na plataforma utilizando o filtro de seguimentos e categorias, os Usuários, potenciais fornecedores, nas categorias "Premium" e "Ultra" são priorizados, recebendo a pesquisa primeiramente e em seguida, com uma diferença de tempo previamente determinado pelo <b>Deixa Que Eu Procuro</b>, são apresentados os resultados com base nos retornos dos Usuários, potenciais fornecedores, que responderem a pesquisa informando a disponibilidade do bem e ou serviço pesquisado.</p>
                                <p className={classes.text}>O Usuário, potencial fornecedor, declara também que, ao responder uma pesquisa, possui em estoque produtos em quantidade suficiente para cumprimento de sua resposta.</p>


                                5.1.2 Processamento de pagamento<br />
                                <p className={classes.text}>O <b>Deixa Que Eu Procuro</b>, não tem nenhuma responsabilidade quanto a negociação de pagamento dos bens e ou serviços negociados, uma vez que, preço, categoria, quantidade, condições de venda, forma e prazo de entrega, além da forma de pagamento serão negociados entre si, fornecedor e cliente, não tendo o site, deixaqueeuprocuro.com.br, nenhuma responsabilidade quanto a esta negociação.</p>

                                5.2 Licença sobre imagens, fotografia, marcas e informações do cadastro, pessoa física ou jurídica.<br />
                                <p className={classes.text}>O usuário autoriza o <b>Deixa Que Eu Procuro</b> e as empresas  de seu grupo econômico a utilizar, publicar, reproduzir e/ou adaptar as imagens e fotografias incluídas em seus cadastros, seu nome comercial, marcas, frases publicitárias, logotipos, desenhos, imagens e qualquer outro sinal distintivo que identifique o Usuário e seus produtos ou serviços ( “Marca”) e as informações de seus produtos e serviços (“Informação do Produto”). Será obrigação do Usuário incluir em seus anúncios as imagens, fotografias e a Marca, bem como a Informação do Produto, atualizada, incluindo as advertências que sejam requeridas pela legislação em vigor para a venda ou publicidade dos produtos e serviços.   Conforme o disposto nesta cláusula, o <b>Deixa Que Eu Procuro</b> poderá obter as imagens. fotografias, Marca e Informação do Produto, diretamente do Usuário, por terceiros autorizados pelo Usuário ou, ainda, através do site do Usuário.</p>
                                <p className={classes.text}>O usuário outorga ao <b>Deixa Que Eu Procuro</b> e às empresas  de seu grupo econômico, uma autorização gratuita, irrevogável, não exclusiva, de abrangência internacional e sem limite temporal , para usar, publicar, reproduzir e/ou adaptar as imagens,  fotografias, Marca e Informação do Produto, com a finalidade de serem usadas em todos os sites do <b>Deixa Que Eu Procuro</b> e das empresas de seu grupo econômico, nas redes sociais e/ou em qualquer meio de comunicação, incluindo, mas não se limitando a plataformas e/ou qualquer outro meio digital ou físico que o <b>Deixa Que Eu Procuro</b> considere oportuno ou ainda em plataformas ou sites com as quais o <b>Deixa Que Eu Procuro</b> tenha realizado parcerias, para identificar ofertas, classificar produtos, criar catálogos, realizar ações publicitárias e de marketing vinculadas aos serviços do <b>Deixa Que Eu Procuro</b>, incluindo a possibilidade de associação com marcas e/ou nomes comerciais de terceiros, bem como sublicenciar o uso a terceiros para os mesmos fins, incluindo autorização de uso, publicação, reprodução e adaptação de imagens, fotografias, Marca e Informação do Produto, no âmbito das Publicações de Catálogo.</p>

                                <b>06 - Privacidade da Informação</b><br />
                                <p className={classes.text}>Toda informação ou Dado Pessoal do Usuário é armazenado em servidores ou meios magnéticos de alta segurança. Salvo com relação às informações que são publicadas nos Sites, o <b>Deixa Que Eu Procuro</b> adotará todas as medidas possíveis para manter a confidencialidade e a segurança das informações sigilosas, porém não se responsabilizará por eventuais prejuízos que sejam decorrentes da divulgação de tais informações por parte de terceiros que utilizem as redes públicas ou a internet, subvertendo os sistemas de segurança para acessar as informações de Usuários.</p>
                                <p className={classes.text}>O Usuário expressamente autoriza que suas informações e dados pessoais sejam compartilhados pelo <b>Deixa Que Eu Procuro</b> com as demais empresas integrantes do grupo econômico, parceiros comerciais, Usuários, potenciais fornecedores e potenciais clientes, autoridades e pessoas físicas ou jurídicas que aleguem ter sido lesadas por Usuários.</p>
                                <p className={classes.text}>Em caso de dúvidas sobre a proteção a Dados Pessoais ou para obter mais informações sobre Dados Pessoais e os casos nos quais poderá ser quebrado o sigilo de que trata esta cláusula, por favor, consultar a página de Privacidade e Confidencialidade.</p>

                                6.1. Responsabilidade assumida pelos terceiros que recebem Informação Pessoal do <b>Deixa Que Eu Procuro</b>.<br />
                                <p className={classes.text}>Em virtude dos acordos celebrados, o <b>Deixa Que Eu Procuro</b> poderá disponibilizar e/ ou receber as Informações Pessoais de terceiros.</p>
                                <p className={classes.text}>O Usuário declara e garante que, ao receber e/ ou disponibilizar Informações Pessoais na relação estabelecida com o <b>Deixa Que Eu Procuro</b>, deverá atuar de acordo com a legislação em vigor e cumprir as seguintes obrigações:</p>

                                <ul>
                                    <li>(i) tratar a Informação Pessoal exclusivamente em razão do objeto do acordo celebrado e conforme as indicações do <b>Deixa Que Eu Procuro</b>, aplicando todas as medidas de segurança que forem necessárias, sejam elas técnicas e/ ou operacionais.</li>
                                    <li>(ii) Notificar imediatamente o <b>Deixa Que Eu Procuro</b> quando da ocorrência de qualquer incidente que tenha potencial de afetar a segurança da Informação Pessoal, incluindo, mas sem se limitar a (a) recebimento de qualquer solicitação de uma autoridade que tiver como objeto a divulgação de Informação Pessoal; e/ ou (b) ocorrência de qualquer incidente de segurança que afete a Informação Pessoal.</li>
                                    <li>(iii) Notificar o <b>Deixa Que Eu Procuro</b> ao receber qualquer solicitação ou queixa por parte dos titulares de dados a respeito da Informação Pessoal, abstendo-se de contestar o titular sem a prévia aprovação, por escrito, do <b>Deixa Que Eu Procuro</b>.</li>
                                    <li>(iv) Excluir e/ ou anonimizar imediatamente a Informação Pessoal em quaisquer dos seguintes casos, salvo se existir algum impedimento legal para tanto: (a) quando tenha terminado a relação jurídica com <b>Deixa Que Eu Procuro</b> ou (b) por instruções expressas e por escrito do <b>Deixa Que Eu Procuro</b></li>
                                    <li>(v) Manter o <b>Deixa Que Eu Procuro</b> indene de qualquer demanda, denuncia, reclamação e/ou procedimento, interposto contra o terceiro pelo titular dos dados pessoais e/ou qualquer autoridade administrativa ou judicial, com o motivo de não cumprimento por ele de suas obrigações em matéria de proteção dos dados pessoais ou de qualquer outra obrigação aqui contemplada.</li>
                                    <li>(vi) Permitir que o <b>Deixa Que Eu Procuro</b> solicite toda a documentação necessária para o cumprimento do dever de segurança contemplado nesta cláusula.</li>
                                </ul>

                                <b>07 - Obrigações dos Usuários</b><br />

                                7.1 Obrigações do Usuário comprador<br />
                                <p className={classes.text}>Os usuários interessados em comprar produtos ou contratar serviços anunciados por vendedores no <b>Deixa Que Eu Procuro</b> devem manifestar seu interesse mediante a pesquisa por bens e ou serviços. Os anúncios tem duração correspondente ao tipo de anúncio e categoria escolhidos pelo Usuário vendedor encerrando-se quando expirado o respectivo prazo de duração ou quando acaba a quantidade do produto anunciado. O Usuário comprador, após manifestar interesse na compra de um item anunciado por meio das ferramentas dos Sites, obriga-se a contatar o Usuário vendedor e concretizar a transação, salvo se a negociação for proibida por lei ou por estes Termos e condições gerais de uso e anexos ou na hipótese de o Usuário vendedor ou comprador desistirem do negócio de comum acordo.</p>
                                <p className={classes.text}>Tributos: O <b>Deixa Que Eu Procuro</b> não se responsabiliza pelas obrigações tributárias que recaiam sobre as atividades dos Usuários dos Sites. Assim como estabelece a legislação pertinente em vigor, o Usuário comprador deverá exigir nota fiscal do Usuário vendedor em suas negociações, salvo se o Usuário vendedor estiver realizando uma venda eventual e não se enquadre no conceito legal de comerciante/empresário quanto aos bens postos em negociação.</p>

                                7.2. Obrigações do Usuário vendedor<br />
                                <p className={classes.text}>Os usuários, potenciais fornecedores, para que possam receber cotações de pesquisas efetuadas pelos usuários, potenciais compradores, precisam adquirir créditos na plataforma do <b>Deixa Que Eu Procuro</b>.</p>
                                <p className={classes.text}>O Usuário vendedor deve ter capacidade legal para vender o produto ou prestar o serviço pesquisado. Os vendedores devem possuir os registros, habilitações, permissões e/ ou autorizações exigidas pela legislação em vigor para oferecer seus produtos e serviços. O Usuário vendedor obriga-se a entrar em contato com o Usuário comprador para efetivar a transação sempre que tenha recebido uma manifestação de interesse na compra do item pesquisado e que se enquadre em seu seguimento.</p>
                                <p className={classes.text}>O Usuário vendedor deve, em cumprimento à legislação brasileira vigente, além de demonstrar informações claras e ostensivas a respeito de quaisquer restrições à aquisição do produto ou serviço, apontar sempre em seus anúncios as características essenciais do produto ou do serviço, incluídos os riscos à saúde e à segurança dos consumidores. Deve ser igualmente apontado pelo Usuário vendedor no anúncio quaisquer despesas adicionais que devam ser pagas pelo Usuário comprador para aquisição do produto ou serviço, tais como despesas de entrega ou seguros. Também deverão ser apontados por todos os Usuários vendedores enquadrados na definição legal de fornecedor, os meios adequados para que os Usuários compradores exerçam seus direitos de arrependimento em relação aos produtos oferecidos em seus anúncios.</p>
                                <p className={classes.text}>Em virtude de o <b>Deixa Que Eu Procuro</b> não figurar como parte nas negociações de compra e venda de serviços e produtos que se realizam entre os Usuários, a responsabilidade por todas as obrigações decorrentes, sejam fiscais, trabalhistas, consumeristas ou de qualquer outra natureza, será exclusivamente do Usuário vendedor. Assim, o Usuário vendedor declara e reconhece que na hipótese de o <b>Deixa Que Eu Procuro</b> ou qualquer empresa do grupo vir a ser demandada judicialmente ou tenha contra ela uma reclamação dos órgãos de proteção ao consumidor, os valores relativos às condenações, acordos, despesas processuais e honorários advocatícios dispendidos pela empresa serão de responsabilidade do Usuário vendedor que deu causa, autorizando, ainda, a retirada dos respectivos valores de seus créditos. Por não figurar como parte nas negociações de compra e venda que se realizam entre os Usuários, o <b>Deixa Que Eu Procuro</b> também não pode obrigar o Usuário vendedor a honrar sua obrigação ou efetivar a negociação.</p>
                                <p className={classes.text}>Tributos: o <b>Deixa Que Eu Procuro</b> não se responsabiliza pelas obrigações de natureza tributária que incidam sobre os negócios realizados entre Usuário comprador e Usuário vendedor. Assim, o Usuário vendedor que atue de forma contínua, nos termos da lei em vigor, responsabilizar-se-á pela integralidade das obrigações oriundas de suas atividades, notadamente pelos tributos incidentes.</p>
                                <p className={classes.text}>O Usuário vendedor deverá ter em mente que, na medida em que atue como um fornecedor de produtos e/ou serviços de forma continua ou eventual, sua oferta o vincula, nos termos do artigo 30 do Código de Defesa do Consumidor e do artigo 429 do Código Civil, cujo cumprimento pode ser exigido judicialmente pelo Usuário comprador.</p>
                                <p className={classes.text}>Por razões de segurança, a conta do Usuário poderá ser suspensa, a critério do <b>Deixa Que Eu Procuro</b>, caso este suspeite de qualquer ilegitimidade, fraude ou qualquer outro ato contrário às disposições dos presentes Termos e condições gerais de uso ou ainda até a apuração e verificação de (i) questões relativas à idoneidade do Usuário; (ii) legalidade das negociações realizadas; (iii) reclamações pendentes; (iv) ausência de valores na conta de créditos na plataforma para arcar com reclamações encerradas negativamente contra o Usuário vendedor; e/ou (v) excesso de reclamações.</p>
                                <p className={classes.text}>O Usuário vendedor aceita que o Usuário comprador exerça o direito de arrependimento dentro do prazo de 7 dias corridos, a contar da data de recebimento do produto. Da mesma forma, o Usuário vendedor aceita que o Usuário comprador solicite a troca ou devolução dos produtos. Em qualquer uma das hipóteses, o vendedor deverá aceitar a troca ou devolução do produto e se encarregar dos gastos envolvidos, incluindo o frete de devolução.</p>

                                <b>08 - Práticas vedadas</b><br />
                                <p className={classes.text}>Os Usuários não poderão, entre outras atitudes previstas nestes Termos e condições gerais e seus anexos: a) interferir nas negociações entre outros Usuários; b) divulgar dados pessoais de contato por qualquer meio; c) anunciar produtos proibidos pelas políticas do <b>Deixa Que Eu Procuro</b> e pela legislação; d) agredir, caluniar, injuriar ou difamar outros Usuários.</p>
                                <p className={classes.text}>Estes tipos de comportamento poderão ser sancionados com o cancelamento do cadastro do usuário, ou com a suspensão da sua conta como Usuário do <b>Deixa Que Eu Procuro</b>, sem prejuízo das ações legais que possam ocorrer pela configuração de delitos ou contravenções ou os prejuízos civis que possam causar aos Usuários compradores, ao <b>Deixa Que Eu Procuro</b> ou terceiros.</p>

                                <b>09 - Violação no sistema ou da base de dados</b><br />
                                <p className={classes.text}>Não é permitida a utilização de nenhum dispositivo, software ou outro recurso que venha a interferir nas atividades e operações do <b>Deixa Que Eu Procuro</b>, bem como nos anúncios, descrições, contas ou seus bancos de dados. Qualquer intromissão, tentativa ou atividade que viole ou contrarie as leis de direito de propriedade intelectual e/ou as proibições estipuladas nestes Termos e condições gerais de uso, tornarão o responsável passível das ações legais pertinentes, bem como das sanções aqui previstas, sendo ainda responsável pelas indenizações por eventuais danos causados.</p>

                                <b>10 - Sanções</b><br />
                                <p className={classes.text}>Sem prejuízo de outras medidas cabíveis, o <b>Deixa Que Eu Procuro</b> poderá advertir, suspender, temporária ou definitivamente, a conta de um Usuário, que ficar por mais de 60 dias sem responder a nenhuma pesquisa, salvo casos que não estejam tendo pesquisas para os seguimentos cadastrados em seu usuário, ou aplicar uma sanção que impacte negativamente em sua reputação, a qualquer tempo, iniciando as ações legais cabíveis e/ou suspendendo a prestação de seus serviços se: a) o Usuário não cumprir qualquer dispositivo destes Termos e condições gerais de uso e demais políticas do <b>Deixa Que Eu Procuro</b>; b) se descumprir com seus deveres de Usuário; c) se praticar atos fraudulentos ou dolosos; d) se não puder ser verificada a identidade do Usuário ou se qualquer informação fornecida por ele estiver incorreta; e) se o <b>Deixa Que Eu Procuro</b> entender que os anúncios ou qualquer atitude do Usuário tenham causado algum dano a terceiros ou ao próprio <b>Deixa Que Eu Procuro</b> ou tenham a potencialidade de assim o fazer. Nos casos suspensão da conta do usuário, todos os anúncios ativos e/ou ofertas realizadas serão automaticamente cancelados e a informação de que Usuário não pertence mais à comunidade será incluída ao lado do nome de Usuário.</p>
                                <p className={classes.text}>O <b>Deixa Que Eu Procuro</b> reserva-se o direito de, a qualquer momento e a seu exclusivo critério, solicitar o envio de documentação pessoal e/ou exigir que um Usuário torne-se ‘Certificado’.</p>

                                <b>11 - Responsabilidades</b><br />
                                <p className={classes.text}>O <b>Deixa Que Eu Procuro</b> não se responsabiliza por vícios ou defeitos técnicos e/ou operacionais oriundos do sistema do Usuário ou de terceiros.</p>
                                <p className={classes.text}>O <b>Deixa Que Eu Procuro</b> não é o proprietário dos produtos ou prestador dos serviços anunciados pelos Usuários nos Sites, não guarda a posse desses itens e não realiza as ofertas de venda, tampouco intervém na entrega dos produtos ou na prestação dos serviços anunciados pelos Usuários nos Sites.</p>
                                <p className={classes.text}>O <b>Deixa Que Eu Procuro</b> não se responsabiliza, por conseguinte, pela existência, quantidade, qualidade, estado, integridade ou legitimidade dos produtos oferecidos, adquiridos ou alienados pelos Usuários, assim como pela capacidade para contratar dos Usuários ou pela veracidade dos dados pessoais por eles inseridos em seus cadastros. O <b>Deixa Que Eu Procuro</b> não outorga garantia por vícios ocultos ou aparentes nas negociações entre os Usuários. Cada Usuário conhece e aceita ser o único responsável pelos produtos que anuncia ou pelas ofertas que realiza.</p>
                                <p className={classes.text}>O <b>Deixa Que Eu Procuro</b> não será responsável pelo efetivo cumprimento das obrigações assumidas pelos Usuários. O Usuário reconhece e aceita ao realizar negociações com outros Usuários ou terceiros o faz por sua conta e risco, reconhecendo o <b>Deixa Que Eu Procuro</b> como mero fornecedor de serviços de disponibilização de espaço virtual para anúncios de produtos e serviços ofertados por terceiros. Em nenhum caso o <b>Deixa Que Eu Procuro</b> será responsável pelo lucro cessante ou por qualquer outro dano e/ou prejuízo que possa sofrer devido às negociações realizadas ou não realizadas por meio dos sites decorrentes da conduta de outros usuários.</p>
                                <p className={classes.text}>O <b>Deixa Que Eu Procuro</b> recomenda que toda negociação seja realizada com cautela e bom senso. O Usuário deverá sopesar os riscos da negociação, levando em consideração que pode estar, eventualmente, lidando com pessoas valendo-se de identidade falsa.</p>
                                <p className={classes.text}>Nos casos em que um ou mais Usuários ou algum terceiro inicie qualquer tipo de reclamação ou ação legal contra outro ou mais Usuários, todos e cada um dos Usuários envolvidos nas reclamações ou ações eximem de toda responsabilidade o <b>Deixa Que Eu Procuro</b> e os seus diretores, gerentes, empregados, agentes, operários, representantes e procuradores, observado, ainda, o estipulado na cláusula 7. Os Usuários têm um prazo de 30 dias corridos, contados a partir da compra, para iniciar uma reclamação contra outro(s) Usuário(s). Uma vez vencido esse prazo, não será possível iniciar uma reclamação através do site do <b>Deixa Que Eu Procuro</b>.</p>
                                <p className={classes.text}>Para a hipótese em que um usuário comprador inicie uma reclamação em face de um Usuário vendedor por meio do site do <b>Deixa Que Eu Procuro</b>, o <b>Deixa Que Eu Procuro</b> se reserva o direito de, a seu exclusivo critério, alterar o motivo da reclamação realizada pelo usuário comprador, quando verificado, por exemplo, divergências nas alegações dos usuários, modalidade de envio, comprovações apresentadas pelo usuário vendedor, entre outras. A alteração do motivo da reclamação pelo <b>Deixa Que Eu Procuro</b> importará na aplicação da medida/sanção cabível ao Usuário, conforme o caso.</p>
                                <p className={classes.text}>Uma vez que o Usuário vendedor tem a faculdade de excluir, perguntar ou impedir outros Usuários de fazer perguntas ou ofertas de compra em seus anúncios, O Usuário vendedor declara, expressamente, que, caso exerça tal faculdade, se responsabilizará exclusivamente por esse ato e assumirá as consequências que dele possam advir, inclusive, ressalvando as responsabilidades decorrentes do art. 39, II da lei n° 8.078/90.</p>

                                <b>12 - Alcance dos serviços</b><br />
                                <p className={classes.text}>Estes Termos e condições gerais de uso não geram nenhum contrato de sociedade, mandato, franquia ou relação de trabalho entre <b>Deixa Que Eu Procuro</b> e o Usuário. O Usuário manifesta ciência de que o <b>Deixa Que Eu Procuro</b> não é parte de nenhuma negociação realizada entre Usuários, nem possui controle algum sobre a existência, qualidade, segurança ou legalidade dos produtos ou serviços anunciados pelos Usuários, sobre a veracidade ou exatidão dos anúncios elaborados pelos usuários e sobre a capacidade dos Usuários para negociar. O <b>Deixa Que Eu Procuro</b> não pode assegurar o êxito de qualquer negociação realizada entre Usuários, tampouco verificar a identidade ou dos Dados Pessoais dos usuários.</p>
                                <p className={classes.text}>O <b>Deixa Que Eu Procuro</b> não garante a veracidade da publicação de terceiros que apareça em seu site e não será responsável pela correspondência ou contratos que o Usuário realize com terceiros.</p>

                                <b>13 - Falhas no sistema</b><br />
                                <p className={classes.text}>O <b>Deixa Que Eu Procuro</b> não se responsabiliza por qualquer dano, prejuízo ou perda sofridos pelo Usuário em razão de falhas na internet, no sistema ou no servidor utilizado pelo usuário, decorrentes de condutas de terceiros, caso fortuito ou força maior. O <b>Deixa Que Eu Procuro</b> também não será responsável por qualquer vírus que possa atacar o equipamento do usuário em decorrência do acesso, utilização ou navegação na internet ou como consequência da transferência de dados, arquivos, imagens, textos, vídeos ou áudio.</p>

                                <b>14 - Tarifas e faturamento</b><br />
                                <p className={classes.text}>O cadastro no <b>Deixa Que Eu Procuro</b> é gratuito. Todavia, para os Usuários, potenciais fornecedores, deverão efetuar a compra de créditos para que possam receber as pesquisas de Usuários, potenciais fornecedores, Estes créditos serão extraídos de sua conta a medida em que recebem cada pesquisa, conforme critério de cobrança, para tanto, os usuários, potenciais fornecedores se comprometem ao ato de cadastrarem na plataforma <b>Deixa Que Eu Procuro</b>, identificarem seus respectivos seguimentos e categorias e uma vez efetuado estarão cientes que receberão pesquisas efetuadas com a cobrança de créditos de sua conta.</p>
                                <p className={classes.text}>O usuário concorda em pagar ao <b>Deixa Que Eu Procuro</b> os valores correspondentes a qualquer pesquisa recebida em seu cadastro, respeitando seus seguimentos e categorias, pelo <b>Deixa Que Eu Procuro</b> em que haja uma tarifa estabelecida. O <b>Deixa Que Eu Procuro</b> se reserva o direito de modificar, aumentar ou excluir tarifas vigentes a qualquer momento, observado o estipulado na cláusula 4 ou durante promoções e outras formas transitórias de alteração dos preços praticados. Tais alterações não vigorarão em relação às negociações e anúncios já iniciados na data em que tais alterações sejam publicadas. Para estes, os Termos e condições gerais de uso valerão com a redação anterior.</p>
                                <p className={classes.text}>O <b>Deixa Que Eu Procuro</b> se reserva o direito de tomar as medidas judiciais e extrajudiciais pertinentes para receber os valores devidos.</p>

                                <b>15 - Sistema de opiniões</b><br />
                                <p className={classes.text}>O usuário comprador conta com um sistema para opinar sobre o usuário vendedor, de acordo com as negociações entre eles realizadas. Esta é uma importante ferramenta e representa a liberdade de expressão do usuário na plataforma do <b>Deixa Que Eu Procuro</b>, podendo se manifestar sobre a relação de compra e venda ocorrida.</p>
                                <p className={classes.text}>O usuário vendedor, inclusive, pode receber comentários por parte do usuário comprador. Esses comentários são de responsabilidade de cada um que os emitirem e serão incluídos em um perfil de usuário disponível ao vendedor.</p>
                                <p className={classes.text}>O <b>Deixa Que Eu Procuro</b> não realiza o monitoramento dos comentários e opiniões e tampouco assume obrigação de verificar a exatidão ou veracidade dos comentários, não se responsabilizando pelo seu conteúdo em decorrência das negociações realizadas ou por qualquer comentário expresso nos sites ou por qualquer outro meio, incluindo e-mail. O <b>Deixa Que Eu Procuro</b> se reserva o direito de a seu exclusivo critério, editar ou excluir os comentários que sejam ofensivos ou que sejam contrários a estes Termos e condições gerais e seus anexos. Ainda, o <b>Deixa Que Eu Procuro</b> poderá excluir as opiniões e/ou os comentários sobre um usuário em cumprimento de uma ordem judicial, administrativa ou de autoridade competente que justifique a alteração.</p>
                                <p className={classes.text}>Conheça mais sobre o Sistema de reputação.</p>

                                <b>16 - Propriedade Intelectual e links</b><br />
                                <p className={classes.text}>O <b>Deixa Que Eu Procuro</b> e/ou suas sociedades controladoras, controladas, filiais ou subsidiárias se reservam todos os direitos, incluindo os direitos de propriedade intelectual e industrial, associados com os serviços do <b>Deixa Que Eu Procuro</b>, seus websites, os conteúdos de suas telas, programas e bases de dados, redes, códigos, desenvolvimento, software, arquitetura, hardware, conteúdo, informação, tecnologia, fases de integração, funcionalidades, domínios, arquivos que permitam ao usuário acessar e criar sua conta, ferramentas de vendas, marcas, patentes, direitos autorais, modelos industriais, nomes comerciais, dentre outros e declaram que estão protegidos pelas leis nacionais e internacionais em vigor.</p>
                                <p className={classes.text}>Em nenhuma hipótese o Usuário terá qualquer tipo de direito sobre eles, exceto para usar o serviço do <b>Deixa Que Eu Procuro</b> conforme previsto nestes Termos e Condições. É proibido o uso indevido ou contrário às normativas vigentes sobre os direitos de propriedade intelectual e industrial do <b>Deixa Que Eu Procuro</b>, bem como sua reprodução total ou parcial, salvo quando autorizado por escrito pelo <b>Deixa Que Eu Procuro</b>.</p>
                                <p className={classes.text}>Os usuários não podem comunicar que os produtos ou serviços que oferecem são patrocinados, promovidos, produzidos, oferecidos e/ou vendidos pelo <b>Deixa Que Eu Procuro</b> e devem abster-se de realizar qualquer ato que possa causar danos, perda de reputação ou diminuição do valor relativo aos direitos de propriedade intelectual e industrial do <b>Deixa Que Eu Procuro</b>.</p>
                                <p className={classes.text}>O site pode conter links para sites de terceiros. Não obstante, como o <b>Deixa Que Eu Procuro</b> não possui controle sobre estes sites, não será responsável pelos conteúdos, materiais, ações e/ou serviços prestados por eles, tampouco por perdas e danos, diretas ou indiretas, ocasionados pela utilização de tais sites. A presença de links de outros sites não implica uma parceria, relação, aprovação ou respaldo do <b>Deixa Que Eu Procuro</b> com tais sites e seus conteúdos.</p>

                                <b>17 - Indenização</b><br />
                                <p className={classes.text}>O usuário indenizará o <b>Deixa Que Eu Procuro</b>, suas filiais, empresas controladas ou controladoras, diretores, administradores, colaboradores, representantes e empregados por qualquer demanda promovida por outros Usuários ou terceiros decorrentes de suas atividades nos Sites ou por seu descumprimento dos Termos e condições gerais de uso e demais políticas do <b>Deixa Que Eu Procuro</b>, ou pela violação de qualquer lei ou direitos de terceiros, incluindo honorários de advogados.</p>

                                <b>18 - Solução de controvérsias</b><br />
                                <p className={classes.text}>O <b>Deixa Que Eu Procuro</b> disponibiliza, gratuitamente aos usuários, uma estrutura para o tratamento de reclamações e também oferece, sem custos, uma equipe especializada.</p>
                                <p className={classes.text}>Tal estrutura visa evitar a judicialização de controvérsias que, eventualmente, surjam na plataforma e, para isso, o <b>Deixa Que Eu Procuro</b> firmou convênios e parcerias com autoridades a fim de promover e incentivar uma solução amigável e eficiente entre o comprador e vendedor da negociação.</p>
                                <p className={classes.text}>Por tais razões, os usuários comprometem-se a utilizar as ferramentas da plataforma como primeiro meio para a solução de controvérsias decorrentes das compras e vendas realizadas no site <b>Deixa Que Eu Procuro</b>.</p>
                                <p className={classes.text}>Os usuários também poderão buscar a solução de controvérsias de consumo por meio do serviço www.consumidor.gov.br, mantido pela Secretaria Nacional do Consumidor (SENACON) do Ministério da Justiça e Segurança Pública, que é disponibilizado gratuitamente aos consumidores de todo o país com o objetivo de prevenir e reduzir a quantidade de controvérsias judicializadas.</p>
                                <p className={classes.text}>O cumprimento das medidas descritas acima visa a efetiva solução da reclamação dos usuários, ou, na hipótese de não ser resolvida, poderá servir como indicativo de demonstrar a resistência à sua pretensão.</p>

                                <b>19 - Legislação aplicável e Foro de eleição</b><br />
                                <p className={classes.text}>Todos os itens destes Termos e condições gerais de uso são regidos pelas leis vigentes na República Federativa do Brasil. Para todos os assuntos referentes à interpretação, ao cumprimento ou qualquer outro questionamento relacionado a estes Termos e condições gerais de uso, as partes concordam em se submeter ao Foro da Cidade de Contagem - MG, com exceção de reclamações apresentadas por Usuários que se enquadrem no conceito legal de consumidores, que poderão submeter tais reclamações ao foro de seu domicílio.</p>

                                <b>20 - Endereço para envio de correspondências</b><br />
                                <p className={classes.text}>Todas as requisições, correspondências, notificações ou pedidos de informação deverão ser enviadas para o endereço do <b>Deixa Que Eu Procuro</b>, na Rua Coronel Antonio Benjamim Camargos, nº 321, Bloco 26, apartamento 402, Bairro Industrial Itaú, Contagem MG, CEP.: 32.240-150.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Contact Page Area  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <Footer />

        </React.Fragment>
    )
}

export default DataPrivacy