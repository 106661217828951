import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import ClientEditRegister from "./register/EditRegister";


class Client extends Component {

    constructor() {
        super();
        const logged = JSON.parse(localStorage.getItem('logged'));
        if(logged === null || !logged) {
            window.location.href = "/login";
        }
    }

    render() {
        let title = 'Área do Cliente';

        return (
            <React.Fragment>
                <PageHelmet pageTitle={title} />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--200 pb--110 bg_image bg_image--17" data-black-overlay="6"></div>
                {/* End Breadcrump Area */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--50 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">

                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h3 className="title">{title}</h3>
                                        </div>

                                        <div className="row mt--40">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                               <ClientEditRegister />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default Client