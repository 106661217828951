import { FormControl, FormHelperText, Grid, Link, makeStyles, TextField } from '@material-ui/core';
import React, { useEffect } from 'react';
import ContactTableCheckBox from '../../component/common/ContactTableCheckBox';
import Loading from '../common/Loading';
import TermsDialog from '../common/TermsDialog';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    input: {
        width: "100%"
    },
    buttonGrid: {
        marginTop: 2,
        marginBottom: 3
    }
}));

const ContactClientSearch = (props) => {
    const classes = useStyles();

    const [openTerm, setOpenTerm] = React.useState(false);
    const [contact, setContact] = React.useState({
        name: "",
        contactList: []
    });
    const [userContact, setUserContact] = React.useState([]);
    const [required, setRequired] = React.useState({
        name: false,
        contactList: false
    });

    const [loading, setLoading] = React.useState(false);

    const changeValue = (event) => {
        setContact({ ...contact, [event.target.name]: event.target.value });
        setRequired({ ...required, [event.target.name]: false });
    }

    const validFields = () => {
        if (contact.name === "") {
            setRequired({ ...required, name: true });
            return false;
        }

        if (contact.contactList === []) {
            setRequired({ ...required, contactList: true });
            return false;
        }

        return true;
    }

    const sendContact = () => {
        if (validFields()) {
            setLoading(true);
            var search = props.search;
            search.contactSearch = contact;

            props.sendSearch(search);
        }
    }

    const changeContact = (type, description, active) => {
        if (active) {
            let contacts = contact.contactList
            contacts.push({ type: type, description: description });
            setContact({ ...contact, contactList: contacts });

            return;
        }

        let contacts = contact.contactList.filter(value => value.description !== description);
        setContact({ ...contact, contactList: contacts });
    }

    useEffect(() => {

        const user = JSON.parse(localStorage.getItem('user'));
        console.log(user);

        if (user !== null) {
            setContact({ name: user.name, contactList: user.contactTypeList });
            setUserContact(user.contactTypeList);
        }

    }, []);

    return (
        <div>
            <Loading open={loading} />
            <FormControl variant="outlined" className={classes.root} >
                <span className="title">Sua busca já será enviada para nossos fornecdores, como gostaria de ser contactado, e os contatos que deseja utilizar?</span>
                <br />
                <Grid container spacing={1} direction="row" justify="center">
                    <Grid item xs={12} md={12} sm={12}>
                        <TextField
                            error={required.name}
                            className={classes.input}
                            name="name"
                            id="name"
                            value={contact.name}
                            onChange={changeValue}
                            label="Como você gostaria de ser chamado?"
                            variant="outlined" />
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                    {userContact.length !== 0 ?
                        <ContactTableCheckBox list={userContact} change={changeContact} /> : null}
                </Grid>

                <Grid className={classes.buttonGrid} container spacing={2}>
                    <Grid item xs={12} md={12} sm={12}>
                        <button
                            className="input-button"
                            type="submit"
                            value="submit"
                            name="submit"
                            onClick={sendContact}>Enviar Informações de Contato</button>
                    </Grid>
                </Grid>
            </FormControl>
            <FormHelperText id="my-helper-text">Ao enviar a busca você concorda com nossos
                <Link className="link link-underline" href="#" onClick={() => setOpenTerm(true)}> termos e condições</Link>.
            </FormHelperText>
            <TermsDialog closeTerms={() => setOpenTerm(false)} openTerm={openTerm} />
        </div >
    );
}

export default ContactClientSearch;