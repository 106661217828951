import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";


class About extends Component {
    render() {
        let title = 'Sobre',
            description = <p align="justify">&emsp; Somos a <b>Deixa que eu procuro</b>, uma plataforma brasileira que, com a obrigação das medidas de distanciamento social, identifica a necessidade de encurtar a distância entre clientes e fornecedores, sem perder o cuidado com a saúde de todos. <br />&emsp; Pensando nisso, tornamos a procura por itens e serviços automotivos mais rápida e segura, uma vez que toda a negociação será efetuada entre comprador e vendedor, sem a necessidade de terceiros.'</p>;
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Sobre' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--200 pb--110 bg_image bg_image--17" data-black-overlay="6"></div>                
                {/* End Breadcrump Area */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--50 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-top">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/about-3.jpg" alt="About Images" />
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">{title}</h2>
                                            <p className="description">{description}</p>
                                        </div>
                                        <div className="row mt--30">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Quem somos</h3>
                                                    <p align="justify">&emsp; O site “Deixa que eu procuro” foi criado com o intuito de auxiliar pessoas e empresas à procura de peças automotivas, nas quais diante do tempo cada vez mais escasso, em razão da globalização, não têm disponibilidade necessária para pesquisa e comparação dos produtos demandados, em sites especializados e fornecedores. </p>
                                                    <p align="justify">&emsp; O site surgiu a partir da ideia central: “DEIXA QUE EU PROCURO PARA VOCÊ!, com a intenção de tornar mais prática a busca de produtos e serviços do ramo automotivo. Desta forma, basta informar as particularidades do que necessita que, enviaremos sua pesquisa para potenciais fornecedores do seguimento escolhido. </p>
                                                    <p align="justify">&emsp; Aos prestadores de serviço, é necessário efetuar um cadastro no qual informará quais os seguimentos automotivos serão fornecidos. Em nossa plataforma, não há necessidade de informar seu estoque, já que o objetivo é que ele seja o mais rotativo possível, seguindo a teoria: “Estoque parado, dinheiro empacado”. </p>
                                                    <p align="justify">&emsp; Nosso diferencial consiste em retornar, ao potencial cliente, o contato dos fornecedores que afirmarem ter disponibilidade do produto ou serviço desejado. Desta forma, o consumidor poupa tempo no processo de pesquisa, comparação e contato para verificar a disposição do item pesquisado.</p>
                                                    <p align="justify">&emsp; O site <b>“deixaqueeuprocuro.com.br”</b> nasceu de pessoas comuns que, passaram por várias vezes por esta situação, na qual perde-se muito tempo de busca. Desejamos agilizar e trazer praticidade, através do cruzamento de dados entre àqueles que buscam e oferecem, levando em conta, inclusive, a localização geográfica e promovendo o contato direto entre os dois indivíduos. </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default About