import React from 'react';
import { useHistory } from "react-router-dom";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    iconSuccess: {
        color: "#04d475",
        fontSize: "8em",
        textAlign: "center"
    },
    iconError: {
        color: "#e02f2f",
        fontSize: "8em",
        textAlign: "center"
    },
    buttonGrid: {
        marginTop: 2,
        marginBottom: 3,
        paddingTop: "2em"
    },
    centerGrid: {
        textAlign: "center"
    },
    subtitle: {
        fontSize: "0,8em"
    },
}));

const MessageDialogSearch = (props) => {
    const classes = useStyles();
    const history = useHistory();

    const closeDialog = () => {
        history.go(0)
        props.close()
    }

    return (
        <div>
            <Grid container direction="row" justify="center" >
                <Grid item xs={12} md={12} sm={12} className={classes.centerGrid}  >
                    {(props.type === "success") ?
                        <CheckCircleOutlineIcon className={classes.iconSuccess} /> :
                        <ErrorOutlineIcon className={classes.iconError} />
                    }
                </Grid>
                <Grid item xs={12} md={12} sm={12} className={classes.centerGrid} >
                    <Typography variant="p" component="p">
                        {props.message}
                    </Typography>

                </Grid>
                <Grid item xs={8} md={8} sm={8} className={classes.centerGrid} >
                    <Typography variant="caption" component="p" className={classes.subtitle}>
                        {props.subtitle}
                    </Typography>
                </Grid>
                <Grid item xs={6} md={6} sm={6} className={classes.buttonGrid} >
                    <button
                        className="input-button"
                        type="click"
                        onClick={() => closeDialog()}
                        id="mc-embedded-subscribe">{props.buttonMessage}</button>
                </Grid>
            </Grid>
        </div>
    );
}

export default MessageDialogSearch;