import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';

const ContactTable = (props) => {
    const getText = (value) => {
        let text = 'N/A';
        switch (value) {
            case "WHATSAPP":
                text = "WhatsApp";
                break;
            case "MAIL":
                text = "Email";
                break;
            case "PHONE":
                text = "Telefone";
                break;
            case "CELLPHONE":
                text = "Celular";
                break;
            default:
                break;
        }

        return text;
    }

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Tipo</TableCell>
                        <TableCell>Descrição</TableCell>
                        <TableCell>Remover</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.list.map((row) => (
                        <TableRow key={row.type}>
                            <TableCell component="th" scope="row">
                                {getText(row.type)}
                            </TableCell>
                            <TableCell align="left">{row.description}</TableCell>
                            <TableCell align="left">
                                <Button variant="contained">
                                    <DeleteIcon onClick={() => props.remove(row)} />
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default ContactTable;