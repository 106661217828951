import React from "react";
import PlatformRequest from "../../repository/PlatformRequest";
import { useHistory } from "react-router-dom";
import Loading from "../common/Loading";
import ReactInputVerificationCode from "react-input-verification-code";

const ResetPasswordForm = (props) => {
    const history = useHistory();
    const [form, setForm] = React.useState({
        login: "",
        token: "",
        newPassword: "",
        confirmNewPassword: ""
    });
    const [loading, setLoading] = React.useState(false);
    const [message, setMessage] = React.useState({
        class: "",
        message: ""
    });
    const [hint, setHint] = React.useState({
        enable: true,
        message: "Informe seu login cadastrado na plataforma"
    })
    const [step, setStep] = React.useState({
        login: true,
        code: false,
        newPassword: false,
    });

    const hideMessage = () => {
        setMessage({ class: "", message: "" })
    }

    const requestResetPassword = () => {
        setLoading(true)
        PlatformRequest.resetPasswordRequest({ login: form.login, rule: "CLIENT" })
            .then((response) => {
                setLoading(false);
                if (response.status !== 200) {
                    setMessage({ class: 'wrong-box', message: "Usuário não encontrado" });
                    return
                }
                setStep({ ...step, login: false, code: true })
                setHint({ enable: true, message: "Será enviado um código de segurança para seu email cadastrado na plataforma, informe esse código no campo abaixo." })
                hideMessage();
            })
    }

    const verifyToken = (value) => {
        if (value.length === 6) {
            setLoading(true)
            setForm({ ...form, token: value })
            PlatformRequest.validateTokenReset(value)
                .then((response) => {
                    setLoading(false);
                    if (response.status !== 200) {
                        setMessage({ class: 'wrong-box', message: "Código Inválido" });
                        return
                    }

                    setStep({ ...step, code: false, newPassword: true })
                    setHint({ enable: false, hint: "" })
                    hideMessage();
                })
        }
    }

    const registerNewPassword = () => {
        if (form.newPassword !== form.confirmNewPassword)
            return setMessage({ class: 'wrong-box', message: "As senhas precisam ser iguais" })


        setLoading(true);
        const body = {
            'token': form.token,
            'newPassword': form.newPassword,
            'confirmNewPassword': form.confirmNewPassword
        }

        PlatformRequest.registerNewPassword(body)
            .then((response) => {
                setLoading(false);
                if (response.status !== 200) {
                    return setMessage({ class: 'wrong-box', message: "Não foi possível cadastrar uma nova senha" })
                }

                alert("Nova Senha Registrada. Utilize a nova senha para realizar login")
                history.push('/login')
            })
    }

    const changeLogin = (event) => {
        setForm({ ...form, login: event.target.value })

    }

    const changePassword = (event) => {
        setForm({ ...form, newPassword: event.target.value })
    }

    const changeConfirmePassword = (event) => {
        setForm({ ...form, confirmNewPassword: event.target.value })
    }

    return (
        <div className="contact-form--1">
            <Loading open={loading} />
            <div className="container">
                <div className="row row--35 align-items-start">
                    <div className="col-lg-12 order-2 order-lg-1">
                        <div className="section-title text-center mb--50">
                            <h3 className="title">
                                Cadastar Nova Senha
                            </h3>
                            {(hint.enable) ? (
                                <span>
                                    {hint.message}
                                </span>
                            ) : null}
                        </div>

                        {/* Start flow */}

                        {/* login */}
                        {(step.login) ? (
                            <div className="form-wrapper">
                                <label htmlFor="item01">
                                    <input
                                        type="text"
                                        name="login"
                                        id="login"
                                        onChange={changeLogin}
                                        value={form.login}
                                        placeholder="Informe seu CPF"
                                    />
                                </label>

                                <div className="text-center mb--50 pt--15">
                                    <button
                                        onClick={requestResetPassword}
                                        className="input-button"
                                        type="click">Enviar</button>
                                </div>
                            </div>
                        ) : null}

                        {/* verification code */}
                        {(step.code) ? (
                            <div>
                                <br />
                                <div className="text-center form-wrapper">
                                    <ReactInputVerificationCode
                                        placeholder=""
                                        onChange={verifyToken}
                                        length={6} />
                                </div>
                            </div>
                        ) : null}

                        {/* new password */}
                        {(step.newPassword) ? (
                            <div className="form-wrapper">
                                <label htmlFor="item01">
                                    <input
                                        type="password"
                                        name="newPassword"
                                        id="newPassword"
                                        onChange={changePassword}
                                        value={form.newPassword}
                                        placeholder="Digite uma nova senha"
                                    />
                                </label>

                                <label htmlFor="item01">
                                    <input
                                        type="password"
                                        name="confirmPassword"
                                        id="confirPassword"
                                        onChange={changeConfirmePassword}
                                        value={form.confirmNewPassword}
                                        placeholder="Confirme a nova senha"
                                    />
                                </label>

                                <div className="text-center mb--50 pt--15">
                                    <button
                                        onClick={registerNewPassword}
                                        className="input-button"
                                        type="click">Cadastrar</button>
                                </div>
                            </div>
                        ) : null}

                        {/* End flow */}

                        <div className={message.class}>
                            {message.message}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
export default ResetPasswordForm;