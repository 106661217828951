const compare = (a, b) => {
    const bandA = a.description.toUpperCase();
    const bandB = b.description.toUpperCase();

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  }

export default compare;