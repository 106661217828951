import React from "react";
import PlatformRequest from "../../repository/PlatformRequest";
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Loading from "../common/Loading";
import { Link } from '@material-ui/core';

const LoginForm = (props) => {
    const history = useHistory();
    const [login, setLogin] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [loginSuccess, setLoginSuccess] = React.useState({
        class: "",
        message: ""
    });

    const changeLogin = (event) => {
        setLogin({ ...login, login: event.target.value });
    }

    const changePassword = (event) => {
        setLogin({ ...login, password: event.target.value });
    }

    const submitLogin = () => {
        setLoading(true);
        setLogin({...login, rule: "CLIENT"})
        PlatformRequest.postLogin(login)
            .then((response) => {
                if (response.status === 401 || response.status === 404) {
                    setLoading(false);
                    setLoginSuccess({ class: 'wrong-box', message: "Usuário ou senha inválidos" });
                    return;
                }
                if (response.status === 200) {
                    const { rule } = jwt_decode(response.body.accessToken);

                    if (rule !== 'PROVIDER') {
                        localStorage.setItem(`jwt`, `Bearer ${response.body.accessToken}`);
                        localStorage.setItem(`logged`, true);
                        return callbackFunction();
                    }
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoginSuccess({ class: 'wrong-box', message: "Usuário ou senha inválidos" });
                setLoading(false);
            })
    }

    const callbackFunction = () => {
        setLoginSuccess({ class: 'success-box', message: "Login realizado!" })

        PlatformRequest
            .getClientFromToken()
            .then((response) => {
                localStorage.setItem(`user`, JSON.stringify(response.body));
                localStorage.setItem('username', response.body.name);
            });

        setTimeout(() => {
            const search = localStorage.getItem('search');
            if (search === null || search === undefined) {
                return history.push("/");
            }

            PlatformRequest
                .postSearch(JSON.parse(search))
                .then(() => {
                    history.push("/success")
                })
                .catch((err) => { setLoading(false) });

        }, 2000)
    }

    const goToRegister = () => {
        history.push("/register")
    }


    const goToReset = () => {
        history.push("/reset")
    }

    return (
        <div className="contact-form--1">
            <Loading open={loading} />
            <div className="container">
                <div className="row row--35 align-items-start">
                    <div className="col-lg-12 order-2 order-lg-1">
                        <div className="section-title text-center mb--50">
                            <h3 className="title">
                                Área do Cliente
                            </h3>
                        </div>
                        <div className="form-wrapper">
                            <label htmlFor="item01">
                                <input
                                    type="text"
                                    name="login"
                                    id="login"
                                    value={login.login}
                                    onChange={changeLogin}
                                    placeholder="Informe seu CPF"
                                />
                            </label>

                            <label htmlFor="item02">
                                <input
                                    type="password"
                                    name="password"
                                    id="password"
                                    onChange={changePassword}
                                    value={login.password}
                                    placeholder="Senha"
                                />
                            </label>
                            <div className="text-right">
                                <Link href="#" onClick={goToReset} className="link link-no-underline">Esqueci minha senha</Link>
                            </div>
                            <br />
                            <button
                                className="input-button"
                                type="click"
                                onClick={submitLogin}>Acessar</button>
                            <br />

                            <div className={loginSuccess.class}>
                                {loginSuccess.message}
                            </div>

                            <div className="text-center mb--50 pt--15">
                                <Link href="#" onClick={goToRegister} className="link link-no-underline">Criar Conta</Link>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default LoginForm;