import React, { Component } from "react";

class About extends Component{
    render(){
        let title = 'Sobre',
        description = 'Somos uma plataforma que une clientes e fornecedores. Desejamos que nossos clientes consigam encontrar sua(s) peça(s) de carro da forma mais acessível, realize sua busca e deixa que procuramos o melhor preço para você,';
        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">

                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about-1.jpg" alt="About Images"/>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">{title}</h2>
                                        <p className="description">{description}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;