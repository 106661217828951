import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";


class BeProvider extends Component {
    render() {
        let title = 'Seja Um Fornecedor',
            description = <p align="justify">Baixe agora mesmo o nosso app e aumente suas vendas. Com a Deixa Que Eu Procuro, você somente informa em qual seguimento atua, adiciona créditos e já irá começar a receber pesquisas dos nossos clientes.</p>;
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Seja um fornecedor' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--200 pb--110 bg_image bg_image--17" data-black-overlay="6"></div>
                {/* End Breadcrump Area */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--50 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container">
                            <div className="row row--45 align-items-top">
                                <div className="col-lg-4">
                                    <div className="thumbnail">
                                        <img className="w-70" src="/assets/images/phone-hand.jpeg" alt="Celular" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">{title}</h2>
                                            <p className="description">{description}</p>
                                        </div>
                                        <div className="row mt--30">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <div className="container">
                                                        <div className="row row--20 align-items-center">
                                                            <div className="col-md-4">
                                                                <img className="w-70" src="/assets/images/google-play.png" alt="google play" />
                                                            </div>
                                                            <div className="col-md-4">
                                                                <img className="w-70" src="/assets/images/ios.png" alt="google play" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default BeProvider