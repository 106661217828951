
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import React from 'react';

const ContactTableCheckBox = (props) => {

    const handleChange = (contactType, description, event) => {
        props.change(contactType, description, event.target.checked);
    };

    return (

        <div>
            <br />
            <Grid container spacing={1} direction="row" justify="center">
                {props.list.map((contact) => (
                    <Grid item xs={12} md={12} sm={12}>
                        <FormControlLabel
                            label={contact.description}
                            control={
                                <Checkbox
                                    defaultChecked
                                    onChange={(e) => { handleChange(contact.type, contact.description, e) }}
                                />
                            }
                        />
                    </Grid>
                ))}
            </Grid>
        </div>

    )
}

export default ContactTableCheckBox;