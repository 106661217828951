import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FiX, FiMenu } from "react-icons/fi";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';


class Header extends Component {
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    }

    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }

    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }

    getFirstName() {
        try {
            const name = localStorage.getItem("username").split(" ")[0];
            return `Olá, ${name}`
        } catch (error) {
            return "Área do cliente"
        }
    }


    render() {
        var elements = document.querySelectorAll('.has-droupdown > a');
        for (var i in elements) {
            if (elements.hasOwnProperty(i)) {
                elements[i].onclick = function () {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        const { color = 'default-color' } = this.props;

        const logout = () => {
            localStorage.removeItem("jwt");
            localStorage.removeItem("logged");
            localStorage.removeItem("user");
            localStorage.removeItem("username");
            window.location.href = "/";
        }

        return (
            <header className={`header-area formobile-menu header--transparent ${color}`}>
                <div className="header-wrapper" id="header-wrapper">
                    <div className="header-left">
                        <div className="logo">
                            <a href="/">
                                <img src="/assets/images/logo.png" alt="Deixa Que Eu Procuro" />
                            </a>
                        </div>
                    </div>
                    <div className="header-right">
                        <nav className="mainmenunav d-lg-block">
                            <ul className="mainmenu">
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/about" >Como Funciona</Link></li>
                                <li><Link to="/provider" >Seja um Fornecedor</Link></li>
                                <li><Link to="/contact" >Contato</Link></li>
                            </ul>
                        </nav>
                        <div className="header-btn">
                            {(localStorage.getItem("logged")) ?
                                <a className="rn-btn" href="/client">
                                    <span>{this.getFirstName()}</span>
                                </a>

                                : <a className="rn-btn" href="/login">
                                    <span>Ja possui conta? Acesse aqui</span>
                                </a>}
                        </div>
                        {(localStorage.getItem("logged")) ?
                            <nav className="mainmenunav d-lg-block">
                                <ul className="mainmenu">
                                    <li></li>
                                    <li><Link onClick={logout}>Sair <ExitToAppIcon /> </Link></li>
                                </ul>
                            </nav>
                            : null}

                        {/* Start Humberger Menu  */}
                        <div className="humberger-menu d-block d-lg-none pl--20">
                            <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                        </div>
                        {/* End Humberger Menu  */}
                        <div className="close-menu d-block d-lg-none">
                            <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}
export default Header;