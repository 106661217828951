const changeMask = (value) => {
    let mask = {};
    switch (value) {
        case "WHATSAPP":
            mask = { mask: "(99) 9 9999-9999", description: "Informe seu WhatsApp" };
            break;
        case "CELLPHONE":
            mask = { mask: "(99) 9 9999-9999", description: "Informe o número do seu celular" };
            break;
        case "PHONE":
            mask = { mask: "(99) 9999-9999", description: "Informe o número do seu telefone" };
            break;
        case "MAIL":
            mask = { mask: "", description: "Informe seu email" };
            break;
        default:
            break;
    }

    return mask;
}

export default changeMask;