import React, { useEffect } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { useHistory } from "react-router-dom";

const SearchSuccess = () => {
    const history = useHistory();

    useEffect(() => {
        const search = localStorage.getItem('search');

        if (search === null || search === undefined) {
            return history.push('/');
        }

        localStorage.removeItem('search');
    });

    const goHome = () => {
        localStorage.removeItem('search');
        return history.push('/');
    }

    let title = 'Busca Realizada!';
    return (
        <React.Fragment>
            <PageHelmet pageTitle={title} />

            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            {/* Start Breadcrump Area */}
            <div className="rn-page-title-area pt--200 pb--110 bg_image bg_image--17" data-black-overlay="6"></div>
            {/* End Breadcrump Area */}

            {/* Start About Area  */}
            <div className="rn-about-area ptb--120 bg_color--1">
                <div className="rn-about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-1"></div>
                            <div className="col-lg-6">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">{title}</h2>
                                    </div>
                                    <div className="row mt--30">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h4 className="title">Ótimo! Agora deixa que eu procuro. Sua busca foi realizada e nossos fornecedores já estão recebendo as informações.</h4>
                                                <p>Fique ligado na forma de contato que deixou informado, será por ela que os forncedores entrarão em contato com você.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="row mt--30">
                                        <div className="col-lg-8 col-md-8 col-sm-8 col-8">
                                            <button
                                                className="input-button"
                                                type="click"
                                                onClick={goHome}>Realizar Nova Busca</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2"></div>
                            <div className="col-lg-3">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about-3.jpg" alt="About Images" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End About Area  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <Footer />

        </React.Fragment>
    )

}
export default SearchSuccess