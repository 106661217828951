import React from 'react';
import { FiMail, FiMapPin, FiPhone } from "react-icons/fi";

const ContactTop = (props) => {

    return (
        <div>

            {/* Start Contact Top Area  */}
            <div className="rn-contact-top-area ptb--120 bg_color--5">
                <div className="container">

                    <div className="row">
                        {/* Start Single Address  */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="rn-address">
                                <div className="icon">
                                    <FiPhone />
                                </div>
                                <div className="inner">
                                    <h4 className="title">Contato Via Telefone</h4>
                                    <p><a href="tel:+057 254 365 456">(31) 2010-2012</a></p>
                                </div>
                            </div>
                        </div>
                        {/* End Single Address  */}

                        {/* Start Single Address  */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                            <div className="rn-address">
                                <div className="icon">
                                    <FiMail />
                                </div>
                                <div className="inner">
                                    <h4 className="title">Email</h4>
                                    <p><a href="mailto:admin@gmail.com">contato@deixaqueeuprocuro.com.br</a></p>
                                </div>
                            </div>
                        </div>
                        {/* End Single Address  */}

                        {/* Start Single Address  */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                            <div className="rn-address">
                                <div className="icon">
                                    <FiMapPin />
                                </div>
                                <div className="inner">
                                    <h4 className="title">Onde estamos?</h4>
                                    <p>Belo Horizonte/MG</p>
                                </div>
                            </div>
                        </div>
                        {/* End Single Address  */}

                    </div>
                </div>
            </div>
            {/* End Contact Top Area  */}

        </div>
    );
}

export default ContactTop;