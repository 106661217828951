import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import compare from '../common/function-compare';
import Chip from '@material-ui/core/Chip';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "100%",

  },
  input: {
    width: "100%",
    height: "4em",
    background: "#fff",
    border: "none",
    paddingLeft: 10,
  },
  chip: {
    marginTop: "8px",
    marginRight: "8px"
  }
}));

const MultipleSelect = (props) => {
  const classes = useStyles();
  const [selectItem, setSelectItem] = React.useState([]);

  const handleChange = (event) => {
    const item = props.list[event.target.value];
    const selects = selectItem;
    selects.push(item)
    setSelectItem(selects);

    props.selectValues(item.id);
  }

  const removeSelected = (id) => {
    const array = selectItem;
    const newSelectItem = array.filter(obj => obj.id !== id);

    setSelectItem(newSelectItem);
    props.onRemove(id);
  }

  const list = props.list.sort(compare).map((item, index) => (
    <option key={index} id={index} value={index}>{item.description}</option>
  ));

  const chipShow = selectItem.map((item, index) => (
    <Chip
      className={classes.chip}
      key={index}
      label={item.description}
      clickable
      onClick={() => removeSelected(item.id)}
      icon={<CancelIcon />} />
  ));

  return (
    <div className={classes.root}>
      <label htmlFor="brand" className="label-input">
        <select
          type="text"
          id={props.id}
          onChange={handleChange}
          className={props.valid ? "input-text input-required" : "input-text"}
        >
          <option selected={true} disabled>{props.placeholder}</option>
          {list}
        </select>
      </label>

      {props.chips ?
        <div>
          {chipShow}
        </div> : null
      }
    </div>
  );
}

export default MultipleSelect;