import React, { useEffect } from 'react';
import { TextField, Grid, makeStyles, Select, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import InputMask from 'react-input-mask';
import changeMask from '../../component/common/function-mask';
import ContactTable from '../../component/common/ContactTable';
import Snackbar from '@material-ui/core/Snackbar';
import PlatformRequest from '../../repository/PlatformRequest';
import Loading from '../common/Loading';
import { useHistory } from "react-router-dom";
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
    inputSize: {
        width: "100%"
    },
    GridSpacing: {
        paddingBottom: 2
    },
    inputError: {
        marginTop: 7,
        padding: 10,
    },
    textError: {
        color: "#e02f2f",
        fontSize: "0.8em"
    }
}));

const ClientEditRegister = () => {
    const history = useHistory();
    const classes = useStyles();
    const [ssn, setSsn] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState({ open: false, message: '' });
    const [state, setState] = React.useState({
        name: '',
        ssn: '',
        contactTypeList: [],
    });
    const [contact, setContact] = React.useState({
        type: '',
        description: '',
    });
    const [mask, setMask] = React.useState({ mask: '', description: '' });
    const [required, setRequired] = React.useState({
        name: false,
        contactTypeList: false,
    });

    const genericChangeValue = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });

        if (event.target.value !== "") {
            setRequired({ ...required, [event.target.name]: false });
        }
    }

    const changeContactType = (event) => {
        const value = event.target.value;
        setContact({ type: value, description: '' });
        setMask(changeMask(value));
    }

    const changeContactDescription = (event) => {
        setContact({ ...contact, description: event.target.value });
    }

    const addContact = () => {
        if (contact.type !== '' && contact.description !== '') {
            let contacts = state.contactTypeList;
            contacts.push(contact);

            setContact({ type: '', description: '' });
            setState({ ...state, contactTypeList: contacts });
        }
    }

    const deleteContact = (row) => {
        let contacts = state.contactTypeList.filter(value => value.description !== row.description);
        setState({ ...state, contactTypeList: contacts });
    }

    const updateClient = () => {
        setOpen(true);
        setState({ ...state, ssn: ssn });

        PlatformRequest.updateClientFromToken(state)
            .then((response) => {
                setOpen(false);

                if (response === 500) {
                    return setMessage({ open: true, message: "Ocorreu algum problema, tente novamente em instantes" })
                }

                const { status } = response;
                if (status === 200) {

                    localStorage.setItem(`user`, JSON.stringify(state));
                    localStorage.setItem('username', state.name);

                    history.push('/client');
                    return setMessage({ open: true, message: "Dados atualizados com sucesso", color: 'success' })
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const messageClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessage({ ...message, open: false });
    };

    useEffect(() => {
        setOpen(true);
        PlatformRequest.getClientFromToken()
            .then((response) => {
                setOpen(false);
                if (response === 401 || response === 404 || response === null) {
                    return history.push('/login');
                }

                const { body } = response;

                setState({ name: body.name, ssn: body.ssn, contactTypeList: body.contactTypeList });
                setSsn(body.ssn);
            });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <Loading open={open} />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                style={{ background: green }}
                open={message.open}
                autoHideDuration={5000}
                message={message.message}
                onClose={messageClose}
            />

            <Grid container alignContent="left" spacing={2} className={classes.GridSpacing}>
                <Grid item xs={12} sm={12} md={12}>
                    <TextField
                        error={required.name}
                        id="outlined-basic"
                        type="text"
                        name="name"
                        value={state.name}
                        onChange={genericChangeValue}
                        className={classes.inputSize}
                        label="Nome"
                        variant="outlined" />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <InputMask
                        mask="999.999.999-99"
                        value={state.ssn}
                        maskChar=" "
                    >
                        {() =>
                            <TextField
                                id="outlined-basic"
                                type="text"
                                name="ssn"
                                value={state.ssn}
                                className={classes.inputSize}
                                label="CPF"
                                variant="outlined" />}
                    </InputMask>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                    <Select
                        native
                        name="type"
                        value={contact.type ? contact.type : ''}
                        onChange={changeContactType}
                        className={classes.inputSize}
                        variant="outlined"
                    >
                        <option selected disabled value="">Tipo</option>
                        <option value="WHATSAPP">WhatsApp</option>
                        <option value="MAIL">Email</option>
                    </Select>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <InputMask
                        mask={mask.mask}
                        value={contact.description}
                        disabled={false}
                        onChange={changeContactDescription}
                        maskChar=" "
                    >
                        {() =>
                            <TextField
                                className={classes.inputSize}
                                name="description"
                                id="outlined-basic"
                                helperText="Informe ao menos um tipo de contato"
                                value={contact.description}
                                onChange={changeContactDescription}
                                label={mask.description}
                                variant="outlined" />}
                    </InputMask>
                </Grid>
                <Grid item xs={2} sm={2} md={2}>
                    <Button variant="contained" color="secondary" onClick={addContact}>
                        <AddIcon />
                    </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    {state.contactTypeList.length !== 0 ?
                        <ContactTable list={state.contactTypeList} remove={deleteContact} /> :
                        null}
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <button
                        className="input-button"
                        type="submit"
                        value="submit"
                        name="submit"
                        onClick={updateClient}
                        id="mc-embedded-subscribe">Salvar</button>
                </Grid>
            </Grid>
        </div>

    )
}

export default ClientEditRegister;