import React from 'react';
import { TextField, Grid, makeStyles, Select, Button, Link, FormHelperText, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import InputMask from 'react-input-mask';
import changeMask from '../../component/common/function-mask';
import ContactTable from '../../component/common/ContactTable';
import Snackbar from '@material-ui/core/Snackbar';
import PlatformRequest from '../../repository/PlatformRequest';
import Loading from '../common/Loading';
import { useHistory } from "react-router-dom";
import TermsDialog from '../common/TermsDialog';
import isValidCPF from './validaCPF';

const useStyles = makeStyles((theme) => ({
    inputSize: {
        width: "100%"
    },
    GridSpacing: {
        paddingBottom: 2
    },
    inputError: {
        marginTop: 7,
        padding: 10,
    },
    textError: {
        color: "#e02f2f",
        fontSize: "0.8em"
    }
}));

const ClientRegister = () => {
    const history = useHistory();
    const classes = useStyles();
    const [openTerm, setOpenTerm] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [invalidSSN, setInvalidSSN] = React.useState(false);
    const [ssnRegistered, setSsnRegistered] = React.useState(false);
    const [message, setMessage] = React.useState({ open: false, message: '' });
    const [state, setState] = React.useState({
        name: '',
        ssn: '',
        contactTypeList: [],
        password: '',
        confirmPassword: ''
    });
    const [contact, setContact] = React.useState({
        type: '',
        description: '',
    });
    const [mask, setMask] = React.useState({ mask: '', description: '' });
    const [required, setRequired] = React.useState({
        name: false,
        ssn: false,
        contactTypeList: false,
        password: false,
        confirmPassword: false
    });

    const genericChangeValue = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });

        if (event.target.value !== "") {
            setRequired({ ...required, [event.target.name]: false });
        }
    }

    const verifySsn = () => {
        const valid = isValidCPF(state.ssn);

        setInvalidSSN(false);
        setSsnRegistered(false);

        if (valid) {
            setInvalidSSN(false);

            PlatformRequest.getExistClient(state.ssn.replace(/[\s.-]*/igm, ''))
                .then((response) => {
                    if (response === 404) {
                        setSsnRegistered(false);
                        setRequired({ ...required, ssn: false });
                        return;
                    }
                    setSsnRegistered(true);
                    setRequired({ ...required, ssn: true });
                });

            return;
        }

        setInvalidSSN(true);
        setRequired({ ...required, ssn: true });
    }

    const changeContactType = (event) => {
        const value = event.target.value;
        setContact({ type: value, description: '' });
        setMask(changeMask(value));
    }

    const changeContactDescription = (event) => {
        setContact({ ...contact, description: event.target.value });
    }

    const addContact = () => {
        if (contact.type !== '' && contact.description !== '') {
            let contacts = state.contactTypeList;
            contacts.push(contact);

            setContact({ type: '', description: '' });
            setState({ ...state, contactTypeList: contacts });
        }
    }

    const deleteContact = (row) => {
        let contacts = state.contactTypeList.filter(value => value.description !== row.description);
        setState({ ...state, contactTypeList: contacts });
    }

    const validInputs = () => {
        if (state.name === "" || state.name === undefined) {
            setRequired({ ...required, name: true });
            return false;
        }

        if (state.ssn === "" || state.ssn === undefined) {
            setRequired({ ...required, ssn: true });
            return false;
        }

        if (state.password === "" || state.password === undefined) {
            setRequired({ ...required, password: true });
            return false;
        }

        if (state.confirmPassword === "" || state.confirmPassword === undefined) {
            setRequired({ ...required, confirmPassword: true });
            return false;
        }

        if (state.contactTypeList.length === 0) {
            setRequired({ ...required, contactTypeList: true });
            return false;
        }

        return true;
    }

    const validPassword = () => {
        if (state.password === state.confirmPassword) {
            setRequired({ ...required, password: false, confirmPassword: false });
            return true;
        }

        setRequired({ ...required, password: true, confirmPassword: true });
        return false;
    }

    const createClient = () => {
        if (validInputs() && validPassword()) {
            setOpen(true);
            PlatformRequest.postClient(state)
                .then((response) => {
                    setOpen(false);
                    if (response === 409) {
                        return setMessage({ open: true, message: "Cliente já cadastrado" })
                    }

                    if (response === 422) {
                        return setMessage({ open: true, message: "CPF Inválido" })
                    }

                    if (response === 500) {
                        return setMessage({ open: true, message: "Ocorreu algum problema, tente novamente em instantes" })
                    }

                    const { status } = response;
                    if (status === 201) {
                        history.push('/login');
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    const messageClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessage({ ...message, open: false });
    };

    return (
        <div>
            <Loading open={open} />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={message.open}
                autoHideDuration={5000}
                message={message.message}
                onClose={messageClose}
            />

            <Grid container alignContent="left" spacing={2} className={classes.GridSpacing}>
                <Grid item xs={12} sm={12} md={12}>
                    <TextField
                        error={required.name}
                        id="outlined-basic"
                        type="text"
                        name="name"
                        value={state.name}
                        onChange={genericChangeValue}
                        className={classes.inputSize}
                        label="Informe seu nome"
                        variant="outlined" />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <InputMask
                        mask="999.999.999-99"
                        value={state.ssn}
                        onBlur={verifySsn}
                        onChange={genericChangeValue}
                        maskChar=" "
                    >
                        {() =>
                            <TextField
                                id="outlined-basic"
                                type="text"
                                name="ssn"
                                error={required.ssn}
                                value={state.ssn}
                                className={classes.inputSize}
                                label="Informe seu CPF"
                                variant="outlined" />}
                    </InputMask>
                    {invalidSSN ?
                        <div className={classes.inputError}>
                            <Typography component="span" className={classes.textError}>
                                CPF inválido
                            </Typography>
                        </div> :
                        null}
                    {ssnRegistered ?
                        <div className={classes.inputError}>
                            <Typography component="span" className={classes.textError}>
                                CPF já cadastrado. Recuperar Senha?
                            </Typography>
                        </div> :
                        null
                    }
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                    <Select
                        native
                        name="type"
                        value={contact.type ? contact.type : ''}
                        onChange={changeContactType}
                        className={classes.inputSize}
                        variant="outlined"
                    >
                        <option selected disabled value="">Tipo</option>
                        <option value="WHATSAPP">WhatsApp</option>
                        <option value="MAIL">Email</option>
                    </Select>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <InputMask
                        mask={mask.mask}
                        value={contact.description}
                        disabled={false}
                        onChange={changeContactDescription}
                        maskChar=" "
                    >
                        {() =>
                            <TextField
                                className={classes.inputSize}
                                name="description"
                                id="outlined-basic"
                                helperText="Informe ao menos um tipo de contato"
                                value={contact.description}
                                onChange={changeContactDescription}
                                label={mask.description}
                                variant="outlined" />}
                    </InputMask>
                </Grid>
                <Grid item xs={2} sm={2} md={2}>
                    <Button variant="contained" color="secondary" onClick={addContact}>
                        <AddIcon />
                    </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    {state.contactTypeList.length !== 0 ?
                        <ContactTable list={state.contactTypeList} remove={deleteContact} /> :
                        null}
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <TextField
                        id="outlined-basic"
                        type="password"
                        name="password"
                        value={state.password}
                        error={required.password}
                        onChange={genericChangeValue}
                        className={classes.inputSize}
                        label="Informe sua senha"
                        variant="outlined" />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <TextField
                        id="outlined-basic"
                        type="password"
                        error={required.confirmPassword}
                        name="confirmPassword"
                        value={state.confirmPassword}
                        onChange={genericChangeValue}
                        className={classes.inputSize}
                        label="Confirme sua senha"
                        helperText="Precisa ser igual a senha"
                        variant="outlined" />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <button
                        className="input-button"
                        type="submit"
                        value="submit"
                        name="submit"
                        onClick={createClient}
                        id="mc-embedded-subscribe">Cadastrar</button>
                </Grid>
            </Grid>
            <FormHelperText id="my-helper-text">Ao clicar em cadastrar você concorda com nossos
                <Link className="link link-underline" href="#" onClick={() => setOpenTerm(true)}> termos e condições</Link>.
            </FormHelperText>
            <TermsDialog closeTerms={() => setOpenTerm(false)} openTerm={openTerm} />
        </div>

    )
}

export default ClientRegister;