import React from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import ResetPasswordForm from "./register/ResetPasswordForm";
import { FiChevronUp } from "react-icons/fi";

const ResetPassword = () => {

    return (
        <React.Fragment>
            <PageHelmet pageTitle='Nova Senha' />

            <Header colorblack="color--black" logoname="logo.png" />

            {/* Start Breadcrump Area */}
            <div className="rn-page-title-area pt--200 pb--110 bg_image bg_image--17" data-black-overlay="6"></div>
            {/* End Breadcrump Area */}

            {/* Start Contact Page Area  */}
            <div className="rn-contact-page ptb--120 bg_color--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-3 order-2 order-lg-1"></div>
                        <div className="col-lg-6 order-2 order-lg-1">
                            <ResetPasswordForm />
                        </div>
                        <div className="col-lg-3 order-2 order-lg-1"></div>
                    </div>
                </div>

            </div>
            {/* End Contact Page Area  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

        </React.Fragment>
    )
}

export default ResetPassword